import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { PageInfoBar } from "@components/common/page-info-bar";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { TeamTabs } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import { InviteMemberToTeam } from "@pages/members/teams/invite-member-to-team";
import { EditableTeamName } from "@pages/members/teams/team-details/editable-team-name";
import { useAppSelector } from "@store/store-helper";
import {
  selectedTeamMembersSelector,
  selectedTeamIdSelector,
} from "@store/teams/teams-selector";

interface Props {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/**
 * Info bar for the team overview tabs
 */
export function TeamDetailsInfoBar({ isLoading = false }: Props): JSX.Element {
  const { teamTabs } = useAppParams();
  const selectedTeamId = useAppSelector(selectedTeamIdSelector);
  const selectedTeamMembers = useAppSelector(selectedTeamMembersSelector);
  const { canEditTeams } = useHasUserValidRoleCompanyLevel();

  if (!selectedTeamId || isLoading) {
    // Early exit with a loading skeleton if the team is not yet loaded
    return <PageInfoBar isLoading={true} />;
  }

  return (
    <PageInfoBar
      items={[
        {
          columnSize: 3,
          content: (
            <EditableTeamName
              team={selectedTeamId}
              isEditable={teamTabs === TeamTabs.members && canEditTeams}
            />
          ),
        },
        {
          columnSize: 6,
          content: (
            <FaroTextField
              label="Members"
              initialValue={selectedTeamMembers.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
      ]}
      action={
        teamTabs === TeamTabs.members ? (
          <InviteMemberToTeam team={selectedTeamId} />
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to keep the position of items constant
          <></>
        )
      }
    />
  );
}
