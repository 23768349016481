import { useCoreApiClient } from "@api/use-core-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useAppParams } from "@router/router-helper";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { fetchCompanyMemberGroups } from "@store/company-member-groups/company-member-groups-thunk";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  companyMemberGroupsSelector,
  companyMemberGroupsStatusSelector,
} from "@store/company-member-groups/company-member-groups-selector";
import { FetchingStatus } from "@store/store-types";
import { mockedTeam } from "@store/teams/teams-slice-utils";

interface UseMemberGroups {
  /** Groups that the member belongs to */
  groups: SphereDashboardAPITypes.CompanyMemberGroup[] | null;

  /** The status of the endpoint */
  companyMemberGroupsStatus: FetchingStatus;
}

/** Fetches the groups of a company member */
export function useMemberGroups(): UseMemberGroups {
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { companyId, memberId, teamId } = useAppParams();
  const { handleErrorWithToast } = useErrorContext();
  const groups = useAppSelector(companyMemberGroupsSelector);
  const companyMemberGroupsStatus = useAppSelector(
    companyMemberGroupsStatusSelector
  );

  // TODO: Remove mockedGroup and use memberId/teamId in fetchCompanyMemberGroups https://faro01.atlassian.net/browse/ST-2645
  useEffect(() => {
    if (companyId && memberId) {
      dispatch(
        fetchCompanyMemberGroups({ coreApiClient, companyId, memberId })
      );
    }
  }, [
    companyId,
    coreApiClient,
    dispatch,
    groups,
    handleErrorWithToast,
    memberId,
  ]);

  if (teamId) {
    return {
      groups: mockedTeam.groups,
      companyMemberGroupsStatus: FetchingStatus.succeeded,
    };
  }

  return { groups, companyMemberGroupsStatus };
}
