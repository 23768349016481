import { TeamState } from "@store/teams/teams-slice-types";
import { RemoveTeamMembersResult } from "@store/teams/teams-slice-types";
import { teamAdapter } from "@store/teams/teams-slice";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";

/**
 * Removes locally a member from a team, by removing it from sample members array
 * attribute of the team if members are available.
 */
export function removeLocalMemberFromTeam(
  state: TeamState,
  result: RemoveTeamMembersResult
): void {
  const team = teamAdapter.getSelectors().selectById(state, result.teamId);
  if (team) {
    const updatedMembers = state.selectedTeam.members;
    for (const memberId in result.memberIds) {
      delete updatedMembers[memberId];
    }

    state.selectedTeam.members = updatedMembers;
  }
}

// TODO: Remove this after actual route is implemented https://faro01.atlassian.net/browse/ST-2645
/** Mocks the team data for group and members */
export const mockedTeam: {
  /** The groups that the team belongs to */
  groups: SphereDashboardAPITypes.CompanyMemberGroup[];

  /** Returns the team as member type */
  teamAsMember: SphereDashboardAPITypes.ICompanyMemberDetails;
} = {
  groups: [
    {
      id: "5321fc4a-f701-4362-a46a-b2f1b375781e",
      createdAt: 1700220018410,
      name: "Osvaldo Picazo Group",
      imageUrl: "",
      thumbnailUrl: "",
    },
    {
      id: "484b581e-bf67-4b59-b4f4-f0859a4bed8c",
      createdAt: 1689174188011,
      name: "Demo",
    },
  ],

  teamAsMember: {
    identity: "1234",
    id: "1234",
    name: "A very nice team",
    email: "no-reply.a-very-nice-team@faro.com",
    projectRoles: [
      {
        id: "cc3522c9-a6ea-4b98-b040-e7e2eda410b1",
        name: "Allstate Arena",
        archivingState: APITypes.ArchivingState.UNARCHIVED,
        role: CoreAPITypes.EUserProjectRole.admin,
      },
      {
        id: "748775e4-8475-4c3a-9d73-f2d10f6a0daa",
        name: "Archived Project",
        archivingState: APITypes.ArchivingState.ARCHIVED,
        role: CoreAPITypes.EUserProjectRole.editor,
      },
    ],
    role: CoreAPITypes.EUserCompanyRole.companyViewer,
    permissions: [],
  },
};
